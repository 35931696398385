export function loadInlineElements() {
    document
        .querySelectorAll<HTMLDivElement>('div[data-inline-content]')
        .forEach(async (el: HTMLDivElement): Promise<void> => {
            const url = el?.dataset?.inlineContent;

            if (url) {
                const txt = await (await fetch(url)).text();
                const tmp = document.createElement('div');

                tmp.innerHTML = txt.trim();
                let i = 0;

                while (i < tmp.childElementCount) {
                    el.parentNode?.insertBefore(tmp.childNodes[i], el);
                    i++;
                }

                el.parentNode?.removeChild(el);
            }
        });
}
