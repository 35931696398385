interface IFrameMessageData {
    type: string;
    payload: unknown;
}

export class HTMLResizeableIframeElement extends HTMLElement {
    private readonly iframe: HTMLIFrameElement;
    private readonly messageChannel = new MessageChannel();

    isInitialized: boolean = false;

    constructor() {
        super();

        this.activateIframe();

        this.messageChannel.port1.onmessage = ({ data }) => this.onMessageReceived(data);

        this.iframe = document.createElement('iframe');
        this.iframe.addEventListener('load', () => this.initIframeCommunication());
        this.iframe.src = this.src;
    }

    get src(): string {
        return this.getAttribute('src') as string;
    }

    private activateIframe() {
        const _elem = document.querySelectorAll('[aria-controls="dist_ajax_contact"]');
        let _isActivated = false;

        Array.from(_elem as NodeListOf<HTMLElement>).forEach((btn) => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();

                if (!_isActivated) {
                    this.appendChild(this.iframe);
                }

                _isActivated = true;
            });
        });
    }

    private initIframeCommunication() {
        // only send/resize postmessage when iframe is not yet initialized.
        if (!this.isInitialized) {
            this.iframe.contentWindow?.postMessage('init', '*', [this.messageChannel.port2]);
            this.iframe.contentWindow?.postMessage({ type: 'subject', payload: location.pathname });
        }

        this.isInitialized = true;
    }

    private onMessageReceived({ type, payload }: IFrameMessageData) {
        switch (type) {
            case 'height':
                this.iframe.style.height = `${payload}px`;
                break;
        }
    }
}

customElements.define('resizeable-iframe', HTMLResizeableIframeElement);
