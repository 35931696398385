import { isDevelopmentEnvironment } from '../helper/isDevelopmentEnvironment';
import { isInIframe } from '../helper/isInIframe';

export class DesktopLanguageSelect {
    private static instance?: DesktopLanguageSelect;

    private readonly languageSelectButton!: HTMLButtonElement;
    private readonly languageSelectList!: HTMLUListElement;

    constructor() {
        const button = document.querySelector<HTMLButtonElement>('.language-select--desktop .language-select__label');
        const list = document.querySelector<HTMLUListElement>('.language-select--desktop .language-select__list');

        if (!button || !list) {
            // we don't need any nav in pages opened in iframe
            // e.g. opening the contact form.
            if (isInIframe()) {
                return;
            }

            if (isDevelopmentEnvironment()) {
                console.error('Desktop language select does not contain button or list');
            }
            return;
        }
        this.languageSelectButton = button;
        this.languageSelectList = list;

        this.languageSelectButton.addEventListener('click', (event) => this.showLanguageList(event));
        document.documentElement.addEventListener('click', () => this.hideLanguageList());
    }

    private showLanguageList(event?: MouseEvent) {
        event?.stopPropagation();
        this.languageSelectList.classList.toggle('d-block');
    }

    private hideLanguageList() {
        this.languageSelectList.classList.remove('d-block');
    }

    static init(): DesktopLanguageSelect | null {
        return this.instance || (this.instance = new DesktopLanguageSelect());
    }
}
