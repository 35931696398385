import { DataTableSortPayload, SortOrder } from './DataTableSort';
import { DataTablePaginatePayload } from './DataTablePagination';
import { TypedEventTarget } from './abstract/TypedEventTarget';

export type DataTableEvents = {
    updated: CustomEvent<number>;
};

export class DataTable extends TypedEventTarget<DataTableEvents> {
    private readonly dataRows: HTMLElement[];
    private readonly emptyFallback: HTMLElement | null = null;

    private filterExpression = '';
    private sortColumn = '';
    private sortOrder = SortOrder.ASCENDING;
    private paginationStart = -1;
    private paginationEnd = Number.MAX_SAFE_INTEGER;

    constructor(private readonly dataWrapper: HTMLElement) {
        super();

        this.dataRows = Array.from(dataWrapper.querySelectorAll<HTMLElement>('[data-row]'));
        this.emptyFallback = dataWrapper.querySelector<HTMLElement>('[data-empty-fallback]');

        this.emitUpdateEvent(this.dataRows.length);
    }

    filter(expression: string) {
        this.filterExpression = expression;
        this.render();
    }

    sort({ column, order }: DataTableSortPayload) {
        this.sortColumn = column;
        this.sortOrder = order;
        this.render();
    }

    paginate({ paginationStart, paginationEnd }: DataTablePaginatePayload) {
        this.paginationStart = paginationStart;
        this.paginationEnd = paginationEnd;
        this.render();
    }

    private render() {
        const filteredElements = this.getFilteredElements(this.dataRows);
        const sortedElements = this.getSortedElements(filteredElements);
        const paginatedElements = this.getPaginatedElements(sortedElements);

        this.clearDataWrapper();
        paginatedElements.forEach((row) => {
            this.dataWrapper.appendChild(row);
        });
        this.emptyFallback?.classList.toggle('d-none', !!filteredElements.length);

        requestAnimationFrame(() => this.emitUpdateEvent(filteredElements.length));
    }

    private getPaginatedElements(items: HTMLElement[]) {
        return items.slice(Math.max(this.paginationStart, 0), Math.min(this.paginationEnd, items.length));
    }

    private getFilteredElements(rows: HTMLElement[]) {
        const term = this.filterExpression.toLowerCase();
        let filtered = rows.filter(
            (row) =>
                row.textContent?.toLowerCase().includes(term) ||
                row.dataset.filterCountries?.toLowerCase().includes(term) ||
                row.dataset.filterContinents?.toLowerCase().includes(term) ||
                false
        );

        return this.getByDownloadUid(filtered);
    }

    private getByDownloadUid(filtered: HTMLElement[]): HTMLElement[] {
        const { searchParams } = new URL(location.href);
        const downloadUid = searchParams.get('file');

        if (downloadUid) {
            const firstElement = filtered.find((row) => row.dataset.downloads?.includes(downloadUid));

            if (firstElement !== undefined) {
                const downloads = firstElement.querySelectorAll('ul .ce-uploads__item');
                downloads.forEach((listItem) => {
                    const item = listItem as HTMLElement;
                    if (item.dataset.downloadUid !== downloadUid) {
                        item.remove();
                    }
                });

                filtered = [firstElement];
            }

            const dataTableElem = document.querySelector('.data-table');
            dataTableElem?.classList.add('hide-product-column');
        }

        return filtered;
    }

    private getSortedElements(rows: HTMLElement[]) {
        return [...rows].sort((a, z) => {
            const columnA = a.querySelector<HTMLElement>(`[data-column="${this.sortColumn}"]`);
            const columnZ = z.querySelector<HTMLElement>(`[data-column="${this.sortColumn}"]`);

            if (columnA && columnZ) {
                const contentA = columnA.textContent!.trim().toLowerCase();
                const contentZ = columnZ.textContent!.trim().toLowerCase();

                if (this.sortOrder === SortOrder.ASCENDING) {
                    return contentZ > contentA ? -1 : 1;
                }
                return contentA > contentZ ? -1 : 1;
            }
            return 0;
        });
    }

    private clearDataWrapper() {
        this.dataRows.forEach((row) => row.remove());
    }

    private emitUpdateEvent(visibleRows: number) {
        const event = new CustomEvent('updated', { detail: visibleRows });
        this.dispatchEvent(event);
    }
}
