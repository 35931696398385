export class FormModalCloser {
    static init() {
        const closer = document.querySelector<HTMLAnchorElement>('#formhandlerClose');
        const back = document.querySelector<HTMLAnchorElement>('#formhandlerBack');

        // console.log('Got closer %o and back %o', closer, back);
        // console.log(
        //     'Comparing current class %o with top class %o',
        //     window.document.body.className,
        //     window.top?.document.body.className
        // );

        if (
            closer &&
            back &&
            window.document.body.classList.contains('belayout-blank') &&
            !window.top?.document.body.classList.contains('belayout-blank')
        ) {
            back.setAttribute('hidden', 'hidden');
            closer.removeAttribute('hidden');
            closer.addEventListener('click', (e) => {
                // @ts-ignore
                window.top.closeModal();
                e.preventDefault();
            });
        }
    }
}
