import { TypedEventTarget } from './abstract/TypedEventTarget';

export const enum SortOrder {
    ASCENDING,
    DESCENDING,
}

export interface DataTableSortPayload {
    column: string;
    order: SortOrder;
}

export type DataTableSortEvents = {
    sort: CustomEvent<DataTableSortPayload>;
};

export class DataTableSort extends TypedEventTarget<DataTableSortEvents> {
    private selectedColumn!: string;
    private selectedOrder!: SortOrder;

    constructor(
        private readonly sortToggles: HTMLElement[],
        private readonly defaultColumn = '',
        private readonly defaultOrder = SortOrder.ASCENDING
    ) {
        super();

        this.sortToggles.forEach((toggle) =>
            toggle.addEventListener('click', () => this.setSortOptionsFromToggle(toggle))
        );

        this.setSortOptions(defaultColumn, defaultOrder);
    }

    setSortOptions(column: string, order: SortOrder) {
        this.selectedOrder = order;
        this.selectedColumn = column;

        this.sortToggles.forEach((toggle) => {
            const isToggleActive = toggle.dataset.sort === column;

            toggle.classList.toggle('sort-active', isToggleActive);
            toggle.classList.toggle('sort-asc', isToggleActive && order === SortOrder.ASCENDING);
            toggle.classList.toggle('sort-desc', isToggleActive && order === SortOrder.DESCENDING);
        });

        const sortEvent = new CustomEvent('sort', {
            detail: {
                column: this.selectedColumn,
                order: this.selectedOrder,
            },
        });

        requestAnimationFrame(() => this.dispatchEvent(sortEvent));
    }

    private setSortOptionsFromToggle(toggle: HTMLElement) {
        let column = toggle.dataset.sort as string;
        let order =
            this.selectedColumn === column
                ? this.selectedOrder === SortOrder.ASCENDING
                    ? SortOrder.DESCENDING
                    : SortOrder.ASCENDING
                : this.defaultOrder;

        if (
            column === this.selectedColumn &&
            column !== this.defaultColumn &&
            this.selectedOrder === SortOrder.DESCENDING
        ) {
            column = this.defaultColumn;
            order = this.defaultOrder;
        }

        this.setSortOptions(column, order);
    }
}
