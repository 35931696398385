import GLightbox from 'glightbox';

export class Ajax {
    private static instance?: Ajax;

    constructor() {
        const button = document.querySelector<HTMLButtonElement>('.js-ajax');
        const select = document.querySelector<HTMLButtonElement>('.js-ajax-select');

        if (button && select) {
            select.addEventListener('change', (event) => {
                console.clear();
                const _srcElement = event.srcElement as HTMLSelectElement;
                if (_srcElement.value !== '0') {
                    button.removeAttribute('disabled');
                } else {
                    button.setAttribute('disabled', 'disabled');
                }
            });

            button.addEventListener('click', (event) => {
                const _clicked = event.srcElement as HTMLButtonElement;
                const _source = _clicked?.dataset?.ajaxSource;

                if (_source) {
                    this.createOverlay();

                    const _element = document.querySelector(_source) as HTMLSelectElement;
                    const _ajaxLightbox = GLightbox({ selector: null, height: 'auto' });

                    if (_element) {
                        const _uri = _element.value;

                        if (_uri !== '0') {
                            const _xhr = new XMLHttpRequest();
                            _xhr.onreadystatechange = function () {
                                if (_xhr.readyState == XMLHttpRequest.DONE) {
                                    if (_xhr.status == 200) {
                                        _ajaxLightbox.insertSlide({
                                            content: _xhr.responseText,
                                        });

                                        _ajaxLightbox.on('open', () => {
                                            const _loading = document.querySelector('.loading');
                                            _loading?.remove();
                                        });

                                        _ajaxLightbox.open();
                                    } else {
                                        console.warn('no ajax call set');
                                    }
                                }
                            };

                            _xhr.open('GET', _uri, true);
                            _xhr.send();
                        }
                    }
                }
            });
        }
    }

    private createOverlay() {
        const el = document.createElement('div');

        // @ts-ignore
        el.textContent = TYPO3.lang['js.loading'];
        el.classList.add('loading');
        const body = document.querySelector('body');
        body?.appendChild(el);
    }

    static init(): Ajax | null {
        return this.instance || (this.instance = new Ajax());
    }
}
