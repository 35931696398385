import { createFocusTrap, FocusTrap } from 'focus-trap';
import { BreakpointHelper } from '../helper/BreakpointHelper';

interface StickyNavComponent {
    isOpen: boolean;
    toggle(opts?: { event?: Event }): void;
    show(opts?: { event?: Event }): void;
    hide(opts?: { event?: Event; dontHideOverlay?: boolean }): void;
}

const instances: StickyNavComponent[] = [];

export class StickyNavChat implements StickyNavComponent {
    public static readonly SESSION_STORAGE_KEY = 'chatActive';

    public static readonly SESSION_STORAGE_LIVECHAT = 'livechat';

    constructor(toggle: HTMLButtonElement) {
        this.testLiveChat()
            .then(() => {
                // remove second btn, when chat has a consent
                // and show the real toggle btn
                toggle.classList.remove('d-none');
                toggle.nextElementSibling?.remove();

                // remove footer button, when active
                document.querySelectorAll<HTMLButtonElement>('.sticky-nav__toggle-button--footer')[0]?.remove();

                // if the chat is opened and consent came from the sticky button,
                // then store the status in the session storage to open it on reload
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const open = urlParams.get('openChat') ? 'true' : 'false';
                sessionStorage.setItem(StickyNavChat.SESSION_STORAGE_KEY, open);
                sessionStorage.setItem(StickyNavChat.SESSION_STORAGE_LIVECHAT, 'y');

                toggle.addEventListener('click', (event) => this.toggle({ event }));
                LiveChatWidget.init();
                LiveChatWidget.on('visibility_changed', ({ visibility }) => this.onChatVisibilityChanged(visibility));
                LiveChatWidget.on('ready', () => {
                    if (open === 'true') {
                        this.show();
                    } else {
                        this.hide();
                    }
                });
            })
            .catch(() => {
                // we do not need to catch the promise failure
            });
    }

    testLiveChat(): Promise<void> {
        const start = Date.now();

        const waitForLiveChat = (resolve: any, reject: any) => {
            if ('LiveChatWidget' in window) {
                resolve();
            } else if (Date.now() - start >= 5000) {
                reject();
            } else {
                setTimeout(waitForLiveChat.bind(this, resolve, reject), 30);
            }
        };

        return new Promise(waitForLiveChat);
    }

    get isOpen() {
        return sessionStorage.getItem(StickyNavChat.SESSION_STORAGE_KEY) === 'true';
    }

    toggle(opts?: { event?: Event }) {
        if (this.isOpen) {
            this.hide(opts);
        } else {
            this.show(opts);
        }
    }

    show({ event }: { event?: Event } = {}) {
        event?.preventDefault();
        LiveChatWidget.call('maximize');
        instances.filter((instance) => instance !== this).forEach((popup) => popup.hide());
        sessionStorage.setItem(StickyNavChat.SESSION_STORAGE_KEY, 'true');
    }

    hide({ event }: { event?: Event; dontHideOverlay?: boolean } = {}) {
        event?.preventDefault();
        if ('LiveChatWidget' in window) {
            // @ts-ignore
            let _type: LiveChatWidgetCalls = sessionStorage.getItem('LiveChatWidgetCall')
                ? sessionStorage.getItem('LiveChatWidgetCall')
                : 'hide';

            if (BreakpointHelper.breakpoint('md', 'up')) {
                _type = 'minimize';
                sessionStorage.setItem('LiveChatWidgetCall', _type);
            }

            LiveChatWidget.call(_type);
        }

        sessionStorage.removeItem('LiveChatWidgetCall');
        sessionStorage.removeItem(StickyNavChat.SESSION_STORAGE_KEY);
    }

    private onChatVisibilityChanged(visibility: LiveChatWidgetVisibility) {
        if (visibility === 'minimized') {
            this.hide();
        }
    }
}

type callback = () => void;

export class StickyNavPopup implements StickyNavComponent {
    private static overlay = document.querySelector('.sticky-nav__overlay') as HTMLElement;
    private static navListElement = document.querySelector('.sticky-nav__list') as HTMLUListElement;

    private readonly focusTrap: FocusTrap;
    private readonly popupMainToggle: HTMLButtonElement;
    private readonly additionalToggles: HTMLButtonElement[];
    private readonly onResizeEventListener = () => this.setTogglePositionVariables();
    private readonly onKeydownEventListener = (event: KeyboardEvent) => this.hideOnEscape(event);

    constructor(private readonly popupElement: HTMLElement) {
        this.popupElement.hidden = true;

        this.popupMainToggle = document.querySelector(
            `.sticky-nav__toggle-button[aria-controls=${this.popupElement.id}]`
        ) as HTMLButtonElement;

        if (this.popupMainToggle) {
            this.popupMainToggle.ariaExpanded = 'false';
            this.popupMainToggle.addEventListener('click', (event) => this.toggle({ event }));
        }

        this.additionalToggles = Array.from(
            document.querySelectorAll<HTMLButtonElement>(
                `[aria-controls=${this.popupElement.id}]:not(.sticky-nav__toggle-button)`
            )
        );
        this.additionalToggles.forEach((toggle) => toggle.addEventListener('click', (event) => this.toggle({ event })));

        this.focusTrap = createFocusTrap(this.popupElement, {
            allowOutsideClick: true,
            initialFocus: () => this.popupElement,
        });

        this.popupElement.querySelector('.sticky-nav__close-popup')?.addEventListener('click', () => this.hide());
    }

    get isOpen() {
        return !this.popupElement.hidden;
    }

    toggle({ event }: { event?: Event } = {}) {
        if (this.isOpen) {
            this.hide({ event });
        } else {
            this.show({ event });
        }
    }

    show({ event }: { event?: Event } = {}) {
        event?.preventDefault();

        instances.forEach((popup) => popup.hide({ dontHideOverlay: true }));
        StickyNavPopup.overlay.hidden = false;
        StickyNavPopup.navListElement.style.zIndex = '9998';
        this.popupMainToggle.ariaExpanded = 'true';
        this.additionalToggles.forEach((toggle) => (toggle.ariaExpanded = 'true'));
        this.popupElement.hidden = false;
        this.setTogglePositionVariables();
        this.focusTrap.activate();
        window.addEventListener('resize', this.onResizeEventListener, { passive: true });
        window.addEventListener('keydown', this.onKeydownEventListener);
    }

    hide({ event, dontHideOverlay }: { event?: Event; dontHideOverlay?: boolean } = {}) {
        event?.preventDefault();

        if (!dontHideOverlay) {
            StickyNavPopup.overlay.hidden = true;
            StickyNavPopup.navListElement.style.zIndex = '';
        }

        if (this.popupMainToggle) {
            this.popupMainToggle.ariaExpanded = 'false';
        }

        this.additionalToggles.forEach((toggle) => (toggle.ariaExpanded = 'false'));
        this.popupElement.hidden = true;
        this.unsetTogglePositionVariables();
        this.focusTrap.deactivate();
        window.removeEventListener('resize', this.onResizeEventListener);
        window.removeEventListener('keydown', this.onKeydownEventListener);
    }

    private setTogglePositionVariables() {
        const toggleBoundingBox = this.popupMainToggle.getBoundingClientRect();
        const toggleCenterX = Math.round(toggleBoundingBox.x + toggleBoundingBox.width / 2);
        const toggleCenterY = Math.round(toggleBoundingBox.y + toggleBoundingBox.height / 2);

        this.popupElement.style.setProperty('--popup-toggle-x', `${toggleCenterX}px`);
        this.popupElement.style.setProperty('--popup-toggle-y', `${toggleCenterY}px`);
    }

    private unsetTogglePositionVariables() {
        this.popupElement.style.setProperty('--popup-toggle-x', null);
        this.popupElement.style.setProperty('--popup-toggle-y', null);
    }

    private hideOnEscape(event: KeyboardEvent) {
        if (event.code === 'Escape') {
            event.preventDefault();
            this.hide();
        }
    }

    public static init(): StickyNavComponent[] {
        if (instances.length) {
            return instances;
        }
        document.querySelectorAll<HTMLDivElement>('.sticky-nav__popup').forEach((popupElement) => {
            instances.push(new StickyNavPopup(popupElement));
        });
        const chatToggle = document.querySelector<HTMLButtonElement>('.sticky-nav__toggle-button--chat');
        if (chatToggle) {
            instances.push(new StickyNavChat(chatToggle));
        }
        this.overlay?.addEventListener('click', () => {
            instances.forEach((instance) => instance.hide());
        });
        return instances;
    }

    public static closer(): callback {
        return function () {
            instances.forEach((i: StickyNavComponent) => i.hide());
        };
    }
}
