import { TypedEventTarget } from './abstract/TypedEventTarget';
import { DataTableSortEvents, SortOrder } from './DataTableSort';

export class DataTableSortSelect extends TypedEventTarget<DataTableSortEvents> {
    constructor(private readonly sortSelect: HTMLSelectElement) {
        super();

        this.sortSelect.addEventListener('change', () => this.onSortSelectChange());
    }

    setSortOptions(column: string, order: SortOrder) {
        this.sortSelect.value = (order === SortOrder.DESCENDING ? '-' : '') + column;
    }

    private onSortSelectChange() {
        let column = this.sortSelect.value;
        let order = SortOrder.ASCENDING;

        if (this.sortSelect.value.startsWith('-')) {
            column = this.sortSelect.value.substring(1);
            order = SortOrder.DESCENDING;
        }

        const sortEvent = new CustomEvent('sort', {
            detail: { column, order },
        });

        requestAnimationFrame(() => this.dispatchEvent(sortEvent));
    }
}
