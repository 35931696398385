/**
 * a find as you type filter class to filter text strings from html elements
 */
export class Filter {
    /**
     * the search string
     *
     * @private
     */
    private static searchString: string;
    private static inputTimeoutId: number | null = null;

    /**
     * the input element where the user types in the search words
     *
     * @private
     */
    private static readonly searchInput = document.getElementById('faq-search') as HTMLInputElement;

    /**
     * the items so search in
     *
     * @private
     */
    private static readonly faqLists = Array.from(
        document.getElementsByClassName('accordion-item') as HTMLCollectionOf<HTMLElement>
    );

    /**
     * init method
     */
    static init(): void {
        this.searchInput?.addEventListener('keyup', () => {
            this.searchString = this.searchInput.value.toLowerCase();

            if (typeof this.inputTimeoutId === 'number') {
                clearTimeout(this.inputTimeoutId);
            }
            this.inputTimeoutId = setTimeout(() => {
                if (this.searchString.length >= 3) {
                    this.toggleClasses('hide');
                    this.find();
                } else {
                    this.toggleClasses('show');
                    console.log('Found ' + this.countItems + ' Items');
                }
                this.inputTimeoutId = null;
            }, 1000);
        });
    }

    /**
     * toggles the items (hide and show)
     *
     * @param type
     * @private
     */
    private static toggleClasses(type: string = 'hide'): void {
        this.faqLists.forEach((el) => {
            if (type === 'hide') {
                el.classList.add('d-none');
            } else {
                el.classList.remove('d-none');
            }
        });
    }

    /**
     * find the desired string in the defined items
     *
     * @private
     */
    private static find(): void {
        this.faqLists.forEach((el) => {
            if (el.innerHTML.toLowerCase().indexOf(this.searchString) !== -1) {
                el.classList.remove('d-none');
            }
        });

        console.log('Found ' + this.countItems + ' Items');
    }

    /**
     * simply returns items that are not hidden
     *
     * @private
     */
    private static get countItems(): number {
        return document.querySelectorAll('.accordion-item:not(.d-none)').length;
    }
}
