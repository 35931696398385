export interface BreakpointHelperOptions {
    breakpoint: string;
}

export class BreakpointHelper {
    constructor() {}

    static breakpoint(breakpoint: string, direction: string = 'up'): boolean {
        const _body: HTMLBodyElement | null = document.getElementById('top') as HTMLBodyElement;

        if (_body) {
            const _allBreakpoints: string[] = window.getComputedStyle(_body)['content'].replaceAll('"', '').split(',');
            const _minWidth: number =
                parseInt(_allBreakpoints[_allBreakpoints.findIndex((element) => element === breakpoint) + 1]) *
                parseFloat(getComputedStyle(document.documentElement).fontSize);

            if (direction === 'up') {
                if (_minWidth <= screen.width) {
                    return true;
                } else {
                    return false;
                }
            }
        }

        return false;
    }
}
