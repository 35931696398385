type TypedEventListenerCallbackOrObject<E extends Event> = TypedEventListenerCallback<E> | TypedEventListenerObject<E>;
interface TypedEventListenerObject<E extends Event> extends EventListenerObject {
    handleEvent(object: E): void;
}
type TypedEventListenerCallback<E extends Event> = (event: E) => void;

export class TypedEventTarget<Events extends Record<string, Event>> extends EventTarget {
    override addEventListener<K extends keyof Events>(
        type: K,
        listener: TypedEventListenerCallbackOrObject<Events[K]> | null,
        options?: boolean | AddEventListenerOptions
    ) {
        // @ts-ignore
        return super.addEventListener(type, listener, options);
    }

    override removeEventListener<K extends keyof Events>(
        type: K,
        listener: TypedEventListenerCallbackOrObject<Events[K]> | null,
        options?: boolean | EventListenerOptions
    ) {
        // @ts-ignore
        return super.removeEventListener(type, listener, options);
    }
}
