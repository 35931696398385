import { MainNav } from './lib/MainNav';
import { SubNav } from './lib/SubNav';
import { Supi } from '../../../../../vendor/supseven/supi/Build/Src/JavaScript/Supi';
import { Autocomplete } from './lib/Autocomplete';
import Splide, { PaginationData } from '@splidejs/splide';
import { Filter } from './lib/Filter';
import { NavItem } from './lib/NavItem';
import { AbstractNav } from './lib/abstract/AbstractNav';
import GLightbox from 'glightbox';
import { MobileLanguageSelect } from './lib/MobileLanguageSelect';
import { DesktopLanguageSelect } from './lib/DesktopLanguageSelect';
import { Ajax } from './lib/Ajax';
import { StickyNavChat, StickyNavPopup } from './lib/StickyNavPopup';
import { DataTableFilter } from './lib/DataTableFilter';
import { DataTable } from './lib/DataTable';
import { DataTableSort, SortOrder } from './lib/DataTableSort';
import { DataTableSortSelect } from './lib/DataTableSortSelect';
import { DataTablePagination } from './lib/DataTablePagination';
import Tooltip from 'bootstrap/js/dist/tooltip';
import Modal from 'bootstrap/js/dist/modal';
import { isFocusable } from 'tabbable';
import { loadInlineElements } from './lib/InlineElement';
import debounce from 'lodash.debounce';

// Global imports
import 'bootstrap/js/dist/collapse';
import Tab from 'bootstrap/js/dist/tab';
import './components/ResizeableIframe';
import { FormModalCloser } from './components/FormModalCloser';
// import { FormTitle } from './components/FormTitle';

function init() {
    (window as any).supi = new Supi();

    initSearchboxes();
    initNavigations();
    initSplideSingleSliders();
    initSplideMultipleSliders();
    initHeroSlider();
    initDataTables();
    initTooltips();
    initDesktopStickyNav();
    loadInlineElements();
    initDownloadModal();
    initSageIframeResizer();
    liveChat();
    animate();
    blogCategoryFilter();

    // find as you type filter
    Filter.init();

    MobileLanguageSelect.init();
    DesktopLanguageSelect.init();

    StickyNavPopup.init();
    (window as any)['closeModal'] = StickyNavPopup.closer();
    FormModalCloser.init();

    if ('LiveChatWidget' in window) {
        // nothing to do here
    } else {
        const _activateChatFooter = document.querySelectorAll<HTMLButtonElement>(
            '.sticky-nav__toggle-button--footer'
        )[0];
        _activateChatFooter?.addEventListener('click', (event) => {
            event.preventDefault();
            console.log('footer clicked');
            document.querySelectorAll<HTMLButtonElement>('.sticky-nav__toggle-button--chat__activate')[0]?.click();
        });
    }

    // Lightbox plugin
    GLightbox({
        selector: '.gsplidebox',
        draggable: false,
    });

    GLightbox({
        selector: '.glightbox',
        draggable: false,
    });

    // Lightbox Plugin for Youtube Videos
    GLightbox({
        selector: '.js-lightbox-iframe',
        width: '75vw',
        height: '85vh',
    });

    // Lightbox Plugin NL Registration
    GLightbox({
        selector: 'main a[href*="youtube.com"]',
    });

    setTranslatedTitleForYoutubeLinks();

    // ajax buttons
    Ajax.init();

    // decrypt email adresses onclick on mailto links
    const mailtoHref = document.querySelectorAll('[data-mailto-token]');
    mailtoHref.forEach((elem: Element) => {
        const anchor = elem as HTMLAnchorElement;
        anchor.addEventListener('click', (e) => {
            e.preventDefault();
            const string = anchor.dataset.mailtoToken;
            if (string) {
                linkTo_UnCryptMailto(string);
            }
        });
    });

    // tabs
    const tabChangeSelect: NodeListOf<HTMLSelectElement> = document.querySelectorAll('.select-holder select');
    tabChangeSelect.forEach((select) => {
        select.addEventListener('change', (e: any) => {
            e.preventDefault();
            const _button = document.querySelector('[data-bs-target="' + e.target.value + '"]') as HTMLButtonElement;
            if (_button) {
                const changeToTab = new Tab(_button);
                changeToTab.show();
            }
        });
    });

    const tabBtns: NodeListOf<HTMLButtonElement> = document.querySelectorAll('button[data-bs-toggle="tab"]');
    tabBtns?.forEach((btn) => {
        btn.addEventListener('shown.bs.tab', () => {
            document.querySelectorAll('.nav-select option').forEach((opt) => {
                opt.removeAttribute('selected');
            });
            const _option = document.querySelector('[value="' + btn?.dataset.bsTarget + '"]') as HTMLOptionElement;
            _option.setAttribute('selected', 'selected');

            document.getElementById('tab-content')?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        });
    });

    // set form subject to product range or product title
    // FormTitle.init();
}

function animate() {
    const elems = document.querySelectorAll('[data-animation]');
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                } else {
                    entry.target.classList.remove('animate');
                }
            });
        },
        {
            rootMargin: '0px 0px -20% 0px',
        }
    );

    elems.forEach((elem) => {
        observer.observe(elem);
    });
}

function liveChat() {
    (window as any).initLivechat = () => {
        const template = <HTMLScriptElement>document.querySelector('[data-supi-cookies="__livechat"]');
        const script = <HTMLScriptElement>document.createElement('script');
        script.className = 'supi-scripts';
        script.async = true;
        script.dataset.supiCookies = template.dataset.supiCookies;
        script.innerHTML = template.innerHTML;

        template.parentNode?.replaceChild(script, template);
    };

    // reload, when banner hides and livechat is activated or
    // the state is different from previous livechat selection
    window.addEventListener('bannerHide', ((e: CustomEvent) => {
        if (e.detail.livechat) {
            const oldStatus = sessionStorage.getItem(StickyNavChat.SESSION_STORAGE_LIVECHAT);
            const newStatus = e.detail.livechat;

            sessionStorage.setItem(StickyNavChat.SESSION_STORAGE_LIVECHAT, e.detail.livechat);

            if (newStatus === 'n') {
                sessionStorage.removeItem(StickyNavChat.SESSION_STORAGE_KEY);
            }

            if (oldStatus && oldStatus !== newStatus) {
                document.location.reload();
            }
        }
    }) as EventListener);
}

function initDownloadModal() {
    if (!document.getElementById('eulaModal')) {
        return;
    }

    const eulaModal = new Modal('#eulaModal');

    document.querySelectorAll<HTMLButtonElement>('.ce-uploads__eulaModal').forEach((modalElement) => {
        modalElement.addEventListener('click', (event) => {
            event.preventDefault();
            document
                .getElementById('downloadLink')
                ?.setAttribute('href', modalElement.dataset.src ? modalElement.dataset.src : '#');
            eulaModal.show();
        });
    });

    document.getElementById('downloadLink')?.addEventListener('click', () => {
        eulaModal.hide();
    });
}

function initDesktopStickyNav() {
    const elevatedClass = 'shadow-sm';
    const isXlAndUpMql = window.matchMedia('(min-width: 75rem)'); // Breakpoint XL -> 1200px
    const header = document.getElementById('header');

    window.addEventListener('scroll', () => {
        header?.classList.toggle(elevatedClass, window.scrollY > 0);
    });

    isXlAndUpMql.addEventListener('change', () => {
        if (!isXlAndUpMql.matches) {
            header?.classList.remove(elevatedClass);
        }
    });
}

function initTooltips() {
    document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((tooltipElement) => {
        new Tooltip(tooltipElement, {
            container: tooltipElement,
            html: true,
        });
    });
}

function setTranslatedTitleForYoutubeLinks() {
    let youtubeLinks = document.querySelector<HTMLElement>('main a[href*="youtube.com"]');
    youtubeLinks?.setAttribute('title', TYPO3.lang['js.youtube']);
}

function initNavigations() {
    const mainNav = MainNav.init();
    const subNavs = SubNav.init();
    NavItem.init();

    mainNav?.addEventListener('afterHide', () => {
        subNavs.forEach((subNav: SubNav) => {
            subNav.hide();
        });
    });

    subNavs.forEach((subNav: SubNav) => {
        subNav.addEventListener('beforeOpen', () => {
            subNavs
                .filter((_subNav) => _subNav !== subNav)
                .filter((_subNav) => !_subNav.element.contains(subNav.element))
                .forEach((_subNav) => _subNav.hide());
        });
    });

    document.documentElement.addEventListener(
        'click',
        (event) => {
            if (AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
                if (event.target instanceof HTMLElement && !mainNav?.itemlistElement?.contains(event.target)) {
                    subNavs.forEach((subNav) => subNav.hide(undefined, false));
                    if (isFocusable(event.target)) {
                        event.target.focus();
                    }
                }
            }
        },
        { capture: true }
    );
}

function initSearchboxes() {
    const searchBoxes = document.querySelectorAll<HTMLElement>(
        '.tx-solr-searchbox.tx-solr-suggest, .tx-solr-search-form .tx-solr-search-form'
    );

    searchBoxes.forEach((container) => {
        const form = container.querySelector<HTMLFormElement>('form');
        const input = form?.querySelector<HTMLInputElement>('input[type=text]');

        if (form != null && input != null) {
            Autocomplete.create(container, form, input);
        }
    });
}

// slider for slides in text media for example. single image slides
function initSplideSingleSliders() {
    // splide slider
    // https://splidejs.com/guides/options/
    Array.from(document.getElementsByClassName('splide-single') as HTMLCollectionOf<HTMLElement>).forEach((item) => {
        const splide = new Splide(item, {
            type: 'loop',
        });

        splide.on('pagination:mounted', (data) => addSpanToPaginationItem(data));

        splide.on('pagination:updated', (data, prev, curr) => {
            if (data) {
                prev.li.classList.remove('is-active');
                curr.li.classList.add('is-active');
            }
        });

        splide.on('updated', function () {
            let refreshed = false;

            if (window.innerWidth > 767 && !refreshed) {
                splide.refresh();
                refreshed = true;
            }
        });

        splide.mount();
    });
}

// splide sliders with multiple images depending on the breakpoint
// used in product details for example
function initSplideMultipleSliders() {
    const minSlidesPerBreakpoint = convertToMediaQueries({
        '48rem': 3, // md
        '75rem': 4, // xl
    });

    // splide slider
    // https://splidejs.com/guides/options/
    Array.from(document.getElementsByClassName('splide-multiple') as HTMLCollectionOf<HTMLElement>).forEach((item) => {
        let splide: Splide | null = null;

        const slideItemsCount = item.querySelectorAll('.splide__slide').length;
        let previousMinSlidesPerPage: number | null = null;

        window.addEventListener(
            'resize',
            debounce(() => updateSlidesPerPage(), 100)
        );

        updateSlidesPerPage();

        function updateSlidesPerPage() {
            const minSlidesPerPage = getMinSlidesPerPage();
            const shouldSplideBeDestroyed = minSlidesPerPage >= slideItemsCount;
            const shouldSplideBeReinitialized = minSlidesPerPage !== previousMinSlidesPerPage;

            if (shouldSplideBeDestroyed || shouldSplideBeReinitialized) {
                splide?.destroy();
                splide = null;
                item.classList.remove('is-initialized');
            }
            if (!shouldSplideBeDestroyed && shouldSplideBeReinitialized) {
                reinitializeSplide();
            }

            item.classList.toggle('is-destroyed', shouldSplideBeDestroyed);
            previousMinSlidesPerPage = minSlidesPerPage;
        }

        function reinitializeSplide() {
            splide = new Splide(item, {
                type: 'loop',
                perPage: getMinSlidesPerPage(),
            });

            splide.on('pagination:mounted', (data) => addSpanToPaginationItem(data));

            splide.on('pagination:updated', (data, prev, curr) => {
                if (data) {
                    prev.li.classList.remove('is-active');
                    curr.li.classList.add('is-active');
                }
            });

            splide.mount();
            // Quickfix to force splide to align items
            window.dispatchEvent(new CustomEvent('resize'));
        }
    });

    function convertToMediaQueries(sizeItemMap: Record<string, number>) {
        return Object.entries(sizeItemMap).map(
            ([minWidth, slides]) => [matchMedia(`(min-width: ${minWidth})`), slides] as [MediaQueryList, number]
        );
    }

    function getMinSlidesPerPage() {
        return minSlidesPerBreakpoint.reduce((output, [mediaQuery, slides]) => {
            return mediaQuery.matches ? slides : output;
        }, 1);
    }
}

function initHeroSlider() {
    if (document.getElementsByClassName('hero-slider').length > 0) {
        const heroSlider = new Splide('#hero-slider', {
            type: 'loop',
            autoplay: true,
            breakpoints: {
                575: {
                    autoplay: false,
                },
            },
        });

        heroSlider.on('pagination:mounted', (data) => addSpanToPaginationItem(data));
        heroSlider.mount();
    }
}

function initDataTables() {
    document.querySelectorAll<HTMLElement>('.data-table').forEach((dataTableContainer) => {
        const filterForm = dataTableContainer.querySelector<HTMLFormElement>('.data-table__filter');
        const dataWrapper = dataTableContainer.querySelector<HTMLElement>('.data-table__data');
        const sortWrapper = Array.from(dataTableContainer.querySelectorAll<HTMLElement>('[data-sort]'));
        const sortSelectElement = dataTableContainer.querySelector<HTMLSelectElement>('.data-table__sort-select');
        const paginationSelectElement = dataTableContainer.querySelector<HTMLSelectElement>('.data-table__pagination');

        let dataTableFilter: DataTableFilter | null;
        let dataTableSort: DataTableSort | null;
        let dataTableSortSelect: DataTableSortSelect | null;
        let dataTablePagination: DataTablePagination | null;

        if (!dataWrapper) {
            console.error('Datatable has no element with class "data-table__data');
            return;
        }

        const dataTable = new DataTable(dataWrapper);

        if (filterForm) {
            dataTableFilter = new DataTableFilter(filterForm);
            dataTableFilter.addEventListener('filter', ({ detail: filterExpression }) => {
                dataTable.filter(filterExpression);
            });
        }

        if (sortWrapper.length) {
            const defaultSort = dataTableContainer.dataset.defaultSort || undefined;
            const defaultOrder = dataTableContainer.dataset.defaultOrder
                ? dataTableContainer.dataset.defaultOrder === 'desc'
                    ? SortOrder.DESCENDING
                    : SortOrder.ASCENDING
                : undefined;

            dataTableSort = new DataTableSort(sortWrapper, defaultSort, defaultOrder);
            dataTableSort.addEventListener('sort', ({ detail: sortOptions }) => {
                dataTable.sort(sortOptions);
            });

            if (sortSelectElement) {
                dataTableSortSelect = new DataTableSortSelect(sortSelectElement);
                dataTableSortSelect.addEventListener('sort', ({ detail: sortOptions }) => {
                    dataTableSort?.setSortOptions(sortOptions.column, sortOptions.order);
                });
                dataTableSort.addEventListener('sort', ({ detail: { column, order } }) => {
                    dataTableSortSelect?.setSortOptions(column, order);
                });
            }
        }

        if (paginationSelectElement) {
            dataTablePagination = new DataTablePagination(paginationSelectElement);
            dataTablePagination.addEventListener('paginate', ({ detail }) => {
                dataTable.paginate(detail);
            });
            dataTable.addEventListener('updated', ({ detail: totalEntries }) => {
                dataTablePagination?.setTotalEntries(totalEntries);
            });

            // @ts-ignore
            dataTableSort?.addEventListener('sort', () => dataTablePagination?.resetPage());
        }
    });
}

function initSageIframeResizer() {
    let sageIframe = document.querySelector<HTMLIFrameElement>('.supi-iframe');
    const sageIframeWrapper = document.querySelector<HTMLElement>('[data-supi-service-type="iframe"]')?.parentNode;
    sageIframeWrapper?.addEventListener('serviceEmbeded', (event) => {
        if ((event as CustomEvent).detail.service === 'sage') {
            sageIframe = (event as CustomEvent).detail.newEl as HTMLIFrameElement;
        }
    });

    window.onmessage = ({ data }) => {
        if (sageIframe) {
            sageIframe.style.height = `${data}px`;
        }
    };
}

function addSpanToPaginationItem(data: PaginationData) {
    if (data.items.length > 5) {
        data.list.classList.add('splide__pagination-numbers');
    }

    data.items.forEach((item) => {
        // @TODO: we might translate the x / x string properly.
        item.button.innerHTML =
            '<span class="splide__pagination__page--indicator"><span class="visually-hidden">' +
            (item.page + 1) +
            ' / ' +
            data.items.length +
            '</span></span>';
    });
}

// decrypt helper function
function decryptCharcode(n: number, start: number, end: number, offset: number) {
    n = n + offset;
    if (offset > 0 && n > end) {
        n = start + (n - end - 1);
    } else if (offset < 0 && n < start) {
        n = end - (start - n - 1);
    }
    return String.fromCharCode(n);
}
// decrypt string
function decryptString(enc: string, offset: number) {
    var dec = '';
    var len = enc.length;
    for (var i = 0; i < len; i++) {
        var n = enc.charCodeAt(i);
        if (n >= 0x2b && n <= 0x3a) {
            dec += decryptCharcode(n, 0x2b, 0x3a, offset); // 0-9 . , - + / :
        } else if (n >= 0x40 && n <= 0x5a) {
            dec += decryptCharcode(n, 0x40, 0x5a, offset); // A-Z @
        } else if (n >= 0x61 && n <= 0x7a) {
            dec += decryptCharcode(n, 0x61, 0x7a, offset); // a-z
        } else {
            dec += enc.charAt(i);
        }
    }
    return dec;
}
// decrypt spam-protected emails
function linkTo_UnCryptMailto(s: string) {
    location.href = decryptString(s, 3);
}

function blogCategoryFilter() {
    const selectElement = document.querySelector<HTMLSelectElement>('.form-select.js-reload');

    if (selectElement) {
        selectElement.addEventListener('change', (event) => {
            const target = event.target as HTMLSelectElement;
            const selectedValue = target.value;
            if (selectedValue) {
                window.location.href = selectedValue;
            }
        });
    }
}

(window as any)['linkTo_UnCryptMailto'] = linkTo_UnCryptMailto;

if (['complete', 'interactive', 'loaded'].includes(document.readyState)) {
    init();
} else {
    document.addEventListener('DOMContentLoaded', () => init());
}
