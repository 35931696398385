export interface TransitionHelperOptions {
    enter: string;
    enterTo: string;
    enterActive: string;
    leave: string;
    leaveTo: string;
    leaveActive: string;
}

export class TransitionHelper {
    private readonly controlElements: HTMLElement[] = [];

    constructor(
        private readonly element: HTMLElement,
        private readonly name: string,
        private readonly options?: Partial<TransitionHelperOptions>
    ) {
        if (element.id) {
            this.controlElements = Array.from(document.querySelectorAll(`[aria-controls=${element.id}]`));
        }
    }

    private isEnterActive = false;
    private isLeaveActive = false;
    private enterFrameId: number | null = null;
    private leaveFrameId: number | null = null;

    private get enterClass(): string {
        return this.options?.enter || `${this.name}-enter`;
    }

    private get enterToClass(): string {
        return this.options?.enterTo || `${this.name}-enter-to`;
    }

    private get enterActiveClass(): string {
        return this.options?.enterActive || `${this.name}-enter-active`;
    }

    private get leaveActiveClass(): string {
        return this.options?.leaveActive || `${this.name}-leave-active`;
    }

    private get leaveClass(): string {
        return this.options?.leave || `${this.name}-leave`;
    }

    private get leaveToClass(): string {
        return this.options?.leaveTo || `${this.name}-leave-to`;
    }

    async show() {
        if (this.isEnterActive) {
            return;
        }
        this.isEnterActive = true;
        this.abortLeaveAnimation();

        this.element.hidden = false;
        this.element.classList.add(this.enterActiveClass, this.enterClass);
        this.enterFrameId = window.requestAnimationFrame(() => {
            this.element.classList.remove(this.enterClass);
            this.element.classList.add(this.enterToClass);
            this.enterFrameId = null;
        });

        try {
            this.setAriaAttributesToControls(true);
            await this.afterTransition();
            this.element.classList.remove(this.enterToClass, this.enterActiveClass);
        } catch (err) {
            this.setAriaAttributesToControls(false);
            this.abortEnterAnimation();
        } finally {
            this.isEnterActive = false;
        }
    }

    async hide() {
        if (this.isLeaveActive) {
            return;
        }
        this.isLeaveActive = true;
        this.abortEnterAnimation();

        this.element.classList.add(this.leaveActiveClass, this.leaveClass);
        this.leaveFrameId = window.requestAnimationFrame(() => {
            this.element.classList.remove(this.leaveClass);
            this.element.classList.add(this.leaveToClass);
            this.leaveFrameId = null;
        });

        try {
            this.setAriaAttributesToControls(false);
            await this.afterTransition();
            this.element.classList.remove(this.leaveToClass, this.leaveActiveClass);
            this.element.hidden = true;
        } catch (err) {
            this.setAriaAttributesToControls(true);
            this.abortLeaveAnimation();
        } finally {
            this.isLeaveActive = false;
        }
    }

    private abortEnterAnimation() {
        this.element.classList.remove(this.enterClass, this.enterToClass, this.enterActiveClass);
        this.isEnterActive = false;
        if (this.enterFrameId !== null) {
            window.cancelAnimationFrame(this.enterFrameId);
            this.leaveFrameId = null;
        }
    }

    private abortLeaveAnimation() {
        this.element.classList.remove(this.leaveClass, this.leaveToClass, this.leaveActiveClass);
        this.isLeaveActive = false;
        if (this.leaveFrameId !== null) {
            window.cancelAnimationFrame(this.leaveFrameId);
            this.leaveFrameId = null;
        }
    }

    private afterTransition(): Promise<void> {
        return new Promise((resolve, reject) => {
            const elementsWithMotion: HTMLElement[] = [];

            const onSuccess = (event: TransitionEvent | AnimationEvent) => {
                elementsWithMotion.splice(elementsWithMotion.indexOf(event.currentTarget as HTMLElement), 1);
                if (elementsWithMotion.length) {
                    return;
                }
                removeAllEventListeners();
                resolve();
            };

            const onFailure = () => {
                removeAllEventListeners();
                reject();
            };

            const removeAllEventListeners = () => {
                this.element.removeEventListener('transitionend', onSuccess);
                this.element.removeEventListener('animationend', onSuccess);
                this.element.removeEventListener('transitioncancel', onFailure);
                this.element.removeEventListener('animationcancel', onFailure);
            };

            const elementStyles = window.getComputedStyle(this.element);
            const hasElementTransitions =
                TransitionHelper.hasElementStyle(elementStyles.transitionDuration) ||
                TransitionHelper.hasElementStyle(elementStyles.transitionDelay);
            if (hasElementTransitions) {
                this.element.addEventListener('transitionend', onSuccess);
                this.element.addEventListener('transitioncancel', onFailure);
            }

            const hasElementAnimations =
                TransitionHelper.hasElementStyle(elementStyles.animationDuration) ||
                TransitionHelper.hasElementStyle(elementStyles.animationDelay);
            if (hasElementAnimations) {
                this.element.addEventListener('animationend', onSuccess);
                this.element.addEventListener('animationcancel', onFailure);
            }

            if (!hasElementTransitions && !hasElementAnimations) {
                resolve();
            }
        });
    }

    private setAriaAttributesToControls(value: boolean | string) {
        this.controlElements.forEach((element) => {
            element.setAttribute('aria-expanded', value.toString());
        });
    }

    private static hasElementStyle(styleValue: string): boolean {
        return (
            styleValue
                .toString()
                .split(',')
                .some((value) => parseFloat(value) !== 0) || false
        );
    }
}
