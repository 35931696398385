import { TypedEventTarget } from './abstract/TypedEventTarget';

export type DataTableFilterEvents = {
    filter: CustomEvent<string>;
};

export class DataTableFilter extends TypedEventTarget<DataTableFilterEvents> {
    private readonly filterInput?: HTMLInputElement;
    private readonly filterSelects: HTMLSelectElement[];

    constructor(private readonly form: HTMLFormElement) {
        super();

        const { searchParams } = new URL(location.href);
        const urlFilter = searchParams.get('filterDealer');
        const urlCountry = searchParams.get('countries');
        const urlDownload = searchParams.get('download');

        this.form.addEventListener('submit', (event) => event.preventDefault());
        this.filterInput = Array.from(this.form.elements).find((element) => element instanceof HTMLInputElement) as
            | HTMLInputElement
            | undefined;
        this.filterSelects = Array.from(this.form.elements).filter(
            (element) => element instanceof HTMLSelectElement
        ) as HTMLSelectElement[];

        Array.from(this.form.elements).forEach((select) =>
            select.addEventListener('input', () => {
                this.onSelectValueChange(select as HTMLSelectElement);
            })
        );

        if (urlFilter || urlCountry || urlDownload) {
            this.setFilter((urlFilter || urlCountry || urlDownload) as string);
        }
    }

    setFilter(value: string) {
        const event = new CustomEvent('filter', { detail: value });

        if (this.filterInput) {
            this.filterInput.value = value;
        }
        this.filterSelects.forEach((select) => (select.value = value));

        requestAnimationFrame(() => this.dispatchEvent(event));
    }

    private onSelectValueChange(select: HTMLSelectElement) {
        this.setFilter(select.value);
    }
}
