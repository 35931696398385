import { AbstractNav, NavOptions } from './abstract/AbstractNav';
import { isDevelopmentEnvironment } from '../helper/isDevelopmentEnvironment';
import { isInIframe } from '../helper/isInIframe';

export class MainNav extends AbstractNav {
    private static instance: MainNav | undefined;

    constructor(options: NavOptions) {
        super(options, 'main-nav');

        AbstractNav.IS_DESKTOP_NAV_MQL.addEventListener('change', (event) => this.onMediaQueryChange(event));
        if (AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
            this.navElement.hidden = false;
        }
    }

    get itemlistElement(): Element | null {
        return this.element.querySelector('.main-nav__itemlist') || null;
    }

    private onMediaQueryChange(event: MediaQueryListEvent) {
        this.navElement.hidden = !event.matches;
        document.documentElement.style.overflowY = '';
        this.setToggleElementExpandedState();
    }

    protected override hideOnEscape(event: KeyboardEvent) {
        if (!AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
            super.hideOnEscape(event);
        }
    }

    public override show(event?: Event): Promise<void> {
        if (!AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
            document.documentElement.style.overflowY = 'hidden';
        }
        return super.show(event);
    }

    public override hide(event?: Event): Promise<void> {
        if (!AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
            document.documentElement.style.overflowY = '';
        }
        return super.hide(event);
    }

    static init(): MainNav | null {
        if (!document.getElementById('main-nav')) {
            // we don't need any nav in pages opened in iframe
            // e.g. opening the contact form.
            if (isInIframe()) {
                return null;
            }

            if (isDevelopmentEnvironment()) {
                console.error(`Cannot find MainNav. ID #main-nav nav does not exist`);
            }

            return null;
        }
        return (
            this.instance ||
            (this.instance = new MainNav({
                navElementId: 'main-nav',
            }))
        );
    }
}
