import { AbstractNav } from './abstract/AbstractNav';

export class NavItem {
    private static instances: NavItem[] = [];

    private element: HTMLElement;
    private sidebar?: HTMLElement;
    private firstLevelNavElement: HTMLElement;
    private minHeightAnimationFrameId: number | null = null;

    constructor(element: HTMLElement) {
        this.element = element;
        this.sidebar = Array.from(this.element.children).find((child) =>
            child.classList.contains('main-nav__sidebar')
        ) as HTMLElement | undefined;

        const firstLevelNavElement = this.element.closest<HTMLElement>('.main-nav__subnav--level-0');
        if (!firstLevelNavElement) {
            throw new Error('Element does not have first level navigation');
        }
        this.firstLevelNavElement = firstLevelNavElement;

        if (this.sidebar) {
            this.element.addEventListener('mouseenter', () => this.setMinHeightOfFirstLevelNavElement());
            this.element.addEventListener('mouseleave', () => this.unsetMinHeightOfFirstLevelNavElement());
            this.element.addEventListener('focusin', () => this.setMinHeightOfFirstLevelNavElement());
            this.element.addEventListener('focusout', (event) => this.unsetMinHeightOfFirstLevelNavElement(event));
        }

        this.element.addEventListener('mouseenter', () => this.setActiveClassToLinkWrapper());
        this.element.addEventListener('mouseleave', () => this.unsetActiveClassToLinkWrapper());
        this.element.addEventListener('focusin', () => this.setActiveClassToLinkWrapper());
        this.element.addEventListener('focusout', (event) => this.unsetActiveClassToLinkWrapper(event));
    }

    private get itemWrapper(): HTMLElement {
        const itemWrapper = Array.from(this.element.children).find((element) =>
            element.classList.contains('main-nav__link-wrapper')
        );
        if (!itemWrapper) {
            throw new Error('Element does not contain link wrapper');
        }
        return itemWrapper as HTMLElement;
    }

    private setActiveClassToLinkWrapper() {
        this.itemWrapper.classList.add('main-nav__link-wrapper--active');
    }

    private unsetActiveClassToLinkWrapper(event?: FocusEvent) {
        const isTargetChildOfCurrentNavigation =
            event?.relatedTarget instanceof HTMLElement &&
            event?.currentTarget instanceof HTMLElement &&
            event?.currentTarget.contains(event?.relatedTarget);
        if (!isTargetChildOfCurrentNavigation) {
            this.itemWrapper.classList.remove('main-nav__link-wrapper--active');
        }
    }

    private setMinHeightOfFirstLevelNavElement() {
        if (AbstractNav.IS_DESKTOP_NAV_MQL.matches) {
            this.clearMinHeightAnimationFrame();
            this.minHeightAnimationFrameId = requestAnimationFrame(() => {
                this.firstLevelNavElement.style.minHeight = '';
                this.minHeightAnimationFrameId = requestAnimationFrame(() => {
                    this.firstLevelNavElement.style.minHeight = `${this.sidebar?.scrollHeight || 0}px`;
                    this.minHeightAnimationFrameId = null;
                });
            });
        }
    }

    private unsetMinHeightOfFirstLevelNavElement(event?: FocusEvent) {
        const isTargetChildOfCurrentNavigation =
            event?.relatedTarget instanceof HTMLElement &&
            event?.currentTarget instanceof HTMLElement &&
            event?.currentTarget.contains(event?.relatedTarget);
        if (!isTargetChildOfCurrentNavigation) {
            this.clearMinHeightAnimationFrame();
            this.firstLevelNavElement.style.minHeight = '';
        }
    }

    private clearMinHeightAnimationFrame() {
        if (typeof this.minHeightAnimationFrameId === 'number') {
            cancelAnimationFrame(this.minHeightAnimationFrameId);
            this.minHeightAnimationFrameId = null;
        }
    }

    static init(): NavItem[] {
        if (!this.instances.length) {
            Array.from(
                document.querySelectorAll<HTMLElement>(
                    '.main-nav__item:not(.main-nav__item--has-subnav):not(.main-nav__item--level-0)'
                )
            ).forEach((item) => {
                this.instances.push(new NavItem(item));
            });
        }
        return this.instances;
    }
}
