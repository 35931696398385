import { TypedEventTarget } from './abstract/TypedEventTarget';

interface ParsedTemplate {
    list: HTMLElement;
    prevButton: HTMLElement | null;
    nextButton: HTMLElement | null;
    placeholder: HTMLElement | null;
    itemTemplate: string;
}

export interface DataTablePaginatePayload {
    paginationStart: number;
    paginationEnd: number;
}

export type DataTablePaginationEvents = {
    paginate: CustomEvent<DataTablePaginatePayload>;
};

export class DataTablePagination extends TypedEventTarget<DataTablePaginationEvents> {
    private static readonly PAGINATION_OVERFLOW_ITEMS = 2;
    private static readonly DOM_PARSER = new DOMParser();

    private readonly parsedTemplate?: ParsedTemplate;

    private page = 99;
    private totalEntries = 1000;
    private entriesPerPage: number;

    constructor(private readonly paginationSelect: HTMLSelectElement) {
        super();

        paginationSelect.addEventListener('change', () => this.onSelectValueChange());
        this.entriesPerPage = parseInt(paginationSelect.value);

        const paginationTemplateSelector = paginationSelect.dataset.paginationTemplate;
        if (paginationTemplateSelector) {
            const template = document.querySelector<HTMLTemplateElement>(paginationTemplateSelector);
            if (template) {
                const paginationElement = template.content.cloneNode(true) as HTMLElement;
                this.parsedTemplate = this.compileTemplate(paginationElement);
                template.replaceWith(paginationElement);
            }
        }
    }

    get paginationDetails(): DataTablePaginatePayload {
        return {
            paginationStart: (this.page - 1) * this.entriesPerPage,
            paginationEnd: (this.page - 1) * this.entriesPerPage + this.entriesPerPage,
        };
    }

    private get totalPages() {
        return Math.ceil(this.totalEntries / this.entriesPerPage);
    }

    setTotalEntries(entries: number) {
        if (entries === this.totalEntries) {
            return;
        }
        this.totalEntries = entries;
        this.page = 1;

        this.render();
    }

    resetPage() {
        this.page = 1;
        this.render();
    }

    private render() {
        if (!this.parsedTemplate) {
            return;
        }
        this.clearPaginationList();

        const paginationStart = Math.max(1, this.page - DataTablePagination.PAGINATION_OVERFLOW_ITEMS);
        const paginationEnd = Math.min(this.totalPages, this.page + DataTablePagination.PAGINATION_OVERFLOW_ITEMS);

        if (this.page > 1 && this.parsedTemplate.prevButton) {
            this.parsedTemplate.list.appendChild(this.parsedTemplate.prevButton);
        }

        if (paginationStart > 1) {
            this.parsedTemplate.list.appendChild(this.createListItem(1));
        }

        if (paginationStart > 2 && this.parsedTemplate.placeholder) {
            this.parsedTemplate.list.appendChild(this.parsedTemplate.placeholder.cloneNode(true));
        }

        for (let i = paginationStart; i <= paginationEnd; i++) {
            this.parsedTemplate.list.appendChild(this.createListItem(i));
        }

        if (paginationEnd < this.totalPages - 1 && this.parsedTemplate.placeholder) {
            this.parsedTemplate.list.appendChild(this.parsedTemplate.placeholder);
        }

        if (paginationEnd < this.totalPages) {
            this.parsedTemplate.list.appendChild(this.createListItem(this.totalPages));
        }

        if (this.page < this.totalPages && this.parsedTemplate.nextButton) {
            this.parsedTemplate.list.appendChild(this.parsedTemplate.nextButton);
        }

        this.emitPaginationEvent();
    }

    private setPage(page: number) {
        page = Math.max(1, Math.min(this.totalPages, page));
        if (this.page !== page) {
            this.page = page;
            this.render();
        }
    }

    private clearPaginationList() {
        if (!this.parsedTemplate) {
            return;
        }
        while (this.parsedTemplate.list.lastChild) {
            this.parsedTemplate.list.removeChild(this.parsedTemplate.list.lastChild);
        }
    }

    private onSelectValueChange() {
        this.entriesPerPage = parseInt(this.paginationSelect.value);
        if (this.entriesPerPage * this.page > this.totalEntries) {
            this.page = 1;
        }
        this.render();
    }

    private createListItem(page: number): HTMLElement {
        const itemMarkup = this.parsedTemplate!.itemTemplate.replaceAll('[number]', page.toString());
        const elementRoot = DataTablePagination.DOM_PARSER.parseFromString(itemMarkup, 'text/html');
        const element = elementRoot.body.firstElementChild as HTMLElement;
        const actionElement = elementRoot.querySelector('[data-pagination-action]');
        actionElement?.addEventListener('click', () => this.setPage(page));

        if (page === this.page) {
            elementRoot
                .querySelectorAll<HTMLElement>('[data-pagination-active]')
                .forEach((el) => el.classList.add(el.dataset.paginationActive!));
        }

        return element;
    }

    private compileTemplate(template: HTMLElement): ParsedTemplate {
        const list = template.querySelector<HTMLElement>('[data-pagination="list"]');
        if (!list) {
            throw new Error('Pagination template does not contain element with attribute data-pagination="list"');
        }
        const item = template.querySelector<HTMLElement>('[data-pagination="item"]');
        if (!item) {
            throw new Error('Pagination template does not contain element with attribute data-pagination="item"');
        }
        const itemTemplate = item.outerHTML;
        const prevButton = this.findAndCompileTemplateAction(template, 'prev', () => this.setPage(this.page - 1));
        const nextButton = this.findAndCompileTemplateAction(template, 'next', () => this.setPage(this.page + 1));
        const placeholder = template.querySelector<HTMLElement>('[data-pagination="placeholder"]');

        return {
            list,
            placeholder,
            prevButton,
            nextButton,
            itemTemplate,
        };
    }

    private findAndCompileTemplateAction(template: HTMLElement, action: string, callback: (event: Event) => unknown) {
        const element = template.querySelector<HTMLElement>(`[data-pagination="${action}"]`);
        const actionElement = element?.querySelector<HTMLElement>('[data-pagination-action]');

        actionElement?.addEventListener('click', (event) => callback(event));

        return element;
    }

    // @ts-ignore
    private emitPaginationEvent() {
        const event = new CustomEvent('paginate', {
            detail: this.paginationDetails,
        });
        this.dispatchEvent(event);
    }
}
